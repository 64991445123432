import moment from 'moment'
import Store from '@/store'
import { MODULE_VIDEO } from '@/model/ValueObject/UserPermissionModules'

const buildFilterQuery = function (filter) {
  var filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
    return filterQuery
  }
  if (filter.videoId) {
    if (filter.videoId.length < 12) {
      filterQuery += '&filter_eq[id]=' + filter.videoId
      return filterQuery
    }
    if (filter.videoId.length === 32) {
      filterQuery += '&filter_eq[ooyalaId]=' + filter.videoId
      return filterQuery
    }
    if (filter.videoId.length < 32) {
      filterQuery += '&filter_eq[brightcoveId]=' + filter.videoId
      return filterQuery
    }
    if (filter.videoId.length === 36) {
      filterQuery += '&filter_eq[liveboxAssetId]=' + filter.videoId
      return filterQuery
    }
  }
  if (filter.title) {
    if (filter.searchIn === 'title') {
      filterQuery += '&filter_contains[meta.title]=' + filter.title
    }
    if (filter.searchIn === 'keywords') {
      filterQuery += '&filter_contains[meta.keywords]=' + filter.title
    }
    if (filter.searchIn === 'description') {
      filterQuery += '&filter_contains[meta.description]=' + filter.title
    }
  }
  if (filter.status) {
    filterQuery += '&filter_eq[status]=' + filter.status
  }
  if (filter.defaultSite) {
    filterQuery += '&filter_eq[defaultSite]=' + filter.defaultSite
  } else {
    const enabledSites = Store.getters['site/enabledSites'](MODULE_VIDEO)
    filterQuery += '&filter_in[defaultSite]=' + enabledSites.map(site => site.id)
  }
  if (filter.type) {
    filterQuery += '&filter_eq[type]=' + filter.type
  }
  if (filter.videoShows && filter.videoShows.length) {
    filterQuery += '&filter_memberOf[videoShows]=' + filter.videoShows.map(videoShow => videoShow.id)
  }
  if (filter.meta_title) {
    filterQuery += '&filter_contains[meta.title]=' + filter.title
  }
  if (filter.meta_description) {
    filterQuery += '&filter_contains[meta.description]=' + filter.meta_description
  }
  if (filter.meta_keywords) {
    filterQuery += '&filter_contains[meta.keywords]=' + filter.meta_keywords
  }
  if (filter.authorUsers && filter.authorUsers.length) {
    filterQuery += '&filter_in[authorUsers]=' + filter.authorUsers.map(user => user.id)
  }
  if (filter.authorUsersDepartments && filter.authorUsersDepartments.length) {
    filterQuery += '&filter_in[authorUsers.department]=' + filter.authorUsersDepartments.map(department => department.id)
  }
  if (filter.cameraUsers && filter.cameraUsers.length) {
    filterQuery += '&filter_memberOf[cameraUsers]=' + filter.cameraUsers.map(user => user.id)
  }
  if (filter.editingUsers && filter.editingUsers.length) {
    filterQuery += '&filter_memberOf[editingUsers]=' + filter.editingUsers.map(user => user.id)
  }
  if (filter.setting_editorialContent) {
    filterQuery += '&filter_eq[setting.editorialContent]=1'
  }
  if (filter.setting_agencyContent) {
    filterQuery += '&filter_eq[setting.agencyContent]=1'
  }
  if (filter.setting_marketingContent) {
    filterQuery += '&filter_eq[setting.marketingContent]=1'
  }
  if (filter.setting_internal) {
    filterQuery += '&filter_eq[setting.internal]=1'
  }
  if (filter.setting_social) {
    filterQuery += '&filter_eq[setting.social]=1'
  }
  if (filter.setting_agency) {
    filterQuery += '&filter_eq[setting.agency]=1'
  }
  if (filter.setting_camera) {
    filterQuery += '&filter_eq[setting.camera]=1'
  }
  if (filter.setting_mobilePhone) {
    filterQuery += '&filter_eq[setting.mobilePhone]=1'
  }
  if (filter.setting_slideshow) {
    filterQuery += '&filter_eq[setting.slideshow]=1'
  }
  if (filter.setting_show) {
    filterQuery += '&filter_eq[setting.show]=1'
  }
  if (filter.setting_forAdults) {
    filterQuery += '&filter_eq[setting.forAdults]=1'
  }
  if (filter.setting_productPlacement) {
    filterQuery += '&filter_eq[setting.productPlacement]=1'
  }
  if (filter.setting_exclusive) {
    filterQuery += '&filter_eq[setting.exclusive]=1'
  }
  if (filter.dateFrom) {
    const date = moment(filter.dateFrom).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[createdAt]=' + date
  }
  if (filter.dateTo) {
    const date = moment(filter.dateTo).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_lte[createdAt]=' + date
  }

  return filterQuery
}

export default {
  buildFilterQuery
}
