const messages = {
  alternative_title: 'Alternative title for Seznam',
  title_seznam: 'Title for Seznam',
  generateHtmlFromPrArticle: {
    btn: 'Generovať HTML štruktúru článku',
    modalTitle: 'HTML of the article',
    btnCopy: 'Copy content'
  },
  article: 'Article',
  foundAnError: 'Found an error',
  articleListError: 'There was an error during loading of the articles',
  id: 'Id',
  editorSwitchBtn: {
    oldEditor: 'Switch to the new editor',
    newEditor: 'Switch to the old editor'
  },
  readAlso: 'Read also',
  commentOnTheTopic: 'Comment on the topic',
  comment: 'Comment',
  relatedArticle: 'Related article',
  review: 'Review',
  usedInArticle: 'Used in article',
  notUsedInAnyArticle: 'Not found in any article.',
  moreOnTheTopic: 'More on the topic',
  document_id: 'Document Id',
  hidden: 'Hidden',
  status: 'Status',
  main_content: 'Main content',
  article_type_title: 'Article type',
  internal: 'Internal',
  external: 'External',
  video: 'Video',
  site: 'Site',
  layout: 'Layout',
  main_rubric: 'Main rubric',
  title: 'Title',
  short_title: 'Short title',
  sub_title: 'SEO title',
  first_paragraph: 'First paragraph',
  multi_drag: 'Bulk image drag&drop',
  show_listing_teaser: 'Another teaser in the list',
  listing_teaser: 'Listing teaser',
  ooyala_id: 'Ooyala #ID',
  video_id: 'Video #ID',
  remove_ooyala_id: 'Remove Ooyala #ID',
  body_text: 'Bodytext',
  body_text_seo_info: '<b>Bodytext</b><br>SEO bodytext info.',
  hero_image: 'Hero image',
  hero_image_display_type: 'Hero image display type',
  hero_image_display_type_big: 'Big',
  hero_image_display_type_small: 'Small',
  hero_video: 'Hero video',
  hero_video_dynamic_preview: 'Dynamic preview',
  another_image_in_the_list: 'Another image in the list',
  listing_image: 'Listing image',
  gallery: 'Gallery',
  related_articles: 'Related articles',
  related_disease_articles: 'Related diseases',
  main_settings: 'Main settings',
  other_rubrics: 'Other rubrics',
  order_date: 'Order date',
  set_order_date_to_now: 'Set now',
  set_published_until: 'Set published until',
  published_until: 'Published until',
  postpone: 'Postpone',
  published_since: 'Published since',
  hiddenOnHp: 'Do not publish on HP',
  author: 'Author',
  authors: 'Authors',
  main_author: 'Main author',
  safety_topics: 'Topics (brand safety)',
  selected_topics: 'Selected topics',
  selected_topics_no_keywords: 'For possibility to set themes, please add keywords',
  owners: 'Owners',
  sharedUsers: 'Author ratio',
  url: 'Url',
  urls: 'URLs',
  meta_info: 'Meta info',
  tags: 'Tags (Meta keywords)',
  tags_geneea: 'Geneea tags',
  tags_geneea_empty: 'No keywords yet',
  tags_geneea_generate: '+ generate keywords',
  tags_geneea_regenerate: '+ regenerate keywords',
  page_titles: 'Page titles:',
  page_titles_car_test_generated_info: 'Page titles for car test are generated automatically: 1: Exteriér, 2: Interiér, 3: Pohon, 4: Podvozok, 5: Cena, 6: Záver',
  page_title_add: 'Add page title',
  quiz_id: 'Quiz #ID',
  recipe_id: 'Recipe #ID',
  test_id: 'Test #ID',
  test_title: 'Test title',
  quiz_title: 'Quiz title',
  recipe_title: 'Recipe title',
  print_publication: 'Printové vydanie',
  create_new_tag: 'Create new',
  create_new_tag_message: 'New keyword was created.',
  flags: 'Flags',
  image_gallery: 'Image gallery',
  exclusive: 'Exclusive',
  commentary: 'Commentary',
  interview: 'Interview',
  online: 'Online',
  updated: 'Updated',
  updated_until_datetime: 'Updated until datetime',
  just_now: 'Just now',
  just_now_until_datetime: 'Just now until datetime',
  poll: 'Poll',
  photostory: 'Photostory',
  quiz: 'Quiz',
  recipe: 'Recipe',
  test: 'Test',
  longform: 'Longform',
  like_dislike: 'Like/Dislike',
  settings: 'Settings',
  disable_comments: 'Disable comments',
  disable_transfer: 'Topic offer exclusion',
  unlocked: 'Unlocked',
  pr: 'PR article',
  native: 'Native',
  editorial_article: 'Redakčný článok',
  copied_from_print: 'Copied from print',
  paid_content: 'Locked/Paid content',
  copyId: 'Copy ID to clipboard',
  proofread_status: 'Proofread status',
  categories: 'Categories',
  video_type: 'Video type',
  system_settings: 'System settings',
  article_history: 'Article history',
  article_history_not_found: 'No history found.',
  time: 'Time',
  type: 'Type',
  user: 'User',
  first_published_date: 'First published date',
  migrated_system: 'Migrated system',
  migrated_id: 'Migrated id',
  migrated_remote_id: 'Migrated remote id',
  unlock_for_edit: 'Unlock for edit',
  created_at: 'Created at',
  edit: 'Edit',
  created_by: 'Created by',
  modified_at: 'Modified at',
  modified_by: 'Modified by',
  external_url: 'External url',
  external_author: 'External author',
  external_site_name: 'External site name',
  external_site_url: 'External site url',
  external_rubric_name: 'External rubric name',
  external_rubric_url: 'External rubric url',
  last_modified: 'Last modified',
  version_info_published: 'Article has 1 published version. Click to open.',
  version_info_draft: 'Article has 1 draft. Click to open.',
  url_link: 'URL',
  url_gallery_link: 'Gallery',
  last_change_info_edited: 'edited',
  activity_is_editing: 'is editing article !',
  antares_info: 'Antares info',
  antares_visits: 'Visits',
  antares_info_not_available: 'Antares information not available',
  title_is_too_short: 'Title is short',
  title_is_too_long: 'Title is too long',
  short_title_is_too_short: 'Short title is short',
  short_title_is_too_long: 'Short title is too long',
  seo_title_invalid: 'SEO title is invalid',
  seo_title_seo_info: '<b>SEO Title</b><br>SEO title info.',
  first_paragraph_is_too_short: 'First paragraph is short',
  first_paragraph_is_too_long: 'First paragraph is too long',
  first_paragraph_seo_info: '<b>First paragraph</b><br>SEO First paragraph info.',
  status_draft: 'Draft',
  status_review: 'Review',
  status_ready: 'Ready',
  status_published: 'Published',
  status_expired: 'Expired',
  status_draft_create: 'Nový koncept',
  status_draft_update: 'Úprava konceptu',
  status_draft_delete: 'Koncept zmazaný',
  status_draft_to_review: 'Poslaný na kontrolu',
  status_review_to_ready: 'Poslaný na publikovanie',
  status_review_to_draft: 'Stiahnutý do konceptu',
  status_ready_to_published: 'Vypublikovaný',
  status_ready_to_draft: 'Stiahnutý do konceptu',
  status_published_to_draft: 'Odpublikovaný do konceptu',
  nature_of_the_article: 'The nature of the article',
  source_type: 'Source type',
  source_type_internal_author: 'Internal author',
  source_type_agency_article: 'Agency',
  source_type_external_author: 'External author',
  source_type_transfered_article: 'Tranfered article',
  source_type_ai_assisted: '🤖 AI Assisted',
  ai_assisted_tooltip: 'Mark 1 or more tools you used to create the article. If you did not find the used tool in the list, enter it in the "Other" item. <br>The use of functions with AI, which are integrated directly in the CMS, is recorded automatically.',
  auto_logout_text: 'Vaša práca bola uložená a boli ste odhlásený z článku',
  doctors: 'Doctors',
  gardeners: 'Gardeners',
  flag: {
    counseling_title: 'The article includes a counseling section'
  },
  ai_service: {
    status_ai_recommended: 'RECOMMENDED',
    generate_from_ai: 'Generate',
    generate_from_ai_again: 'Close and generate again',
    ai_generated: 'Recommended keywords. Please select:',
    show_generated: 'Show generated suggestions',
    select_generated: 'Choose 1 of the recommended perex (you can edit it later)',
    replace: 'Use as',
    add_after: 'Add after',
    fill_in_the_title: 'Fill in the Title',
    not_enough_chars: 'Add {charsLeft} more characters to the Article Text',
    no_generated_results: 'Could not generate any results.',
    recommended_articles_error_title: 'We\'re sorry, but we can\'t display related articles right now.',
    recommended_articles_error_body: 'The text of the article is too short for us to make relevant recommendations. Please try to expand the article or add more content so that we can offer you suitable suggestions.',
    recommended_articles_service_unavailable: 'We\'re sorry, but we can\'t display related articles right now. Please try again later.',
    ai_modal: {
      modal_title_heading: 'A/B/C testing of headlines',
      modal_perex_heading: 'Perex',
      modal_keyword_heading: 'Keywords',
      modal_title_hint: 'Select the B and C versions of the headline.',
      modal_perex_hint: 'Choose 1 of the recommended perex (you can edit it later)',
      modal_keyword_hint: 'Choose the appropriate keywords',
      modal_use_button: 'Use in article',
      modal_input_err_min: 'The keyword must contain min. 2 characters.',
      modal_input_err_duplicate: 'The field contains a keyword identical to another field.',
      modal_related_modal_tooltip: 'The article is recommended by an algorithm based on artifficial intelligence that looks for the highest match in the text between your article and other recently published articles.',
      modal_socialMediaPost_heading: 'Social Media Post',
      modal_socialMediaPost_hint: 'Select or edit the social media post.'
    },
    social_media_generator: {
      card_title: 'Social Media',
      card_title_tooltip: 'This feature creates a recommended text for sharing the article on social networks. The suggestion is generated from the Title field, so it can only work with the information contained in the article title.',
      generate_btn: 'Generate Status',
      post_length: {
        title: 'Post Length',
        title_tooltip: 'Approximate number of characters:<br><br>' +
          'Short = 100<br>' +
          'Medium = 150<br>' +
          'Long = 200',
        short: 'Short',
        medium: 'Medium',
        long: 'Long'
      },
      type_of_interaction: {
        title: 'Type of Interaction',
        title_tooltip: 'Discussion<br> Encourages to leave a comment<br><br>' +
          'Read<br> Encourages to read the article<br><br>' +
          'Share<br> Encourages sharing the article',
        discussion: 'Discussion',
        reading: 'Reading',
        sharing: 'Sharing'
      }
    }
  },
  ab_test: {
    title: 'A/B/C Testing',
    variant: 'Variant',
    winner: 'winner',
    impressions_count: 'Impressions count',
    clicks_count: 'Clicks count',
    ctr: 'CTR (clickability)',
    duration: 'Duration',
    info: {
      title: 'Tests start after article is published and ends as correct winning Title a Image is set. Tests run through all NMH sites and templates.',
      image: 'If no image is set, hero image is used.'
    }
  },
  list: {
    type: 'Type',
    title: 'Title',
    site: 'Site',
    main_rubric: 'Main rubric',
    author: 'Author',
    order_date: 'Order date',
    last_modified: 'Last modified',
    status: 'Status'
  },
  filter: {
    site: 'Site',
    rubric: 'Rubric',
    search_in: 'Search in',
    title: 'Title',
    fulltext: 'Fulltext',
    id_url: 'Search by UUID / URL',
    published_since: 'Published since',
    published_until: 'Published until',
    type: 'Article type',
    authors: 'Authors',
    status: 'Status',
    tags: 'Tags',
    categories: 'Categories',
    users: 'Owners',
    search: 'Search',
    reset_filter: 'Reset filter',
    my_last_modified: 'My last modified',
    my_drafts: 'My drafts',
    my_articles: 'My articles',
    all: 'All',
    all_drafts: 'All drafts',
    to_review: 'To review',
    waiting_to_publish: 'Waiting to publish',
    published: 'Published',
    just_edited: 'Just edited'
  },
  transfer_to_export: {
    title: 'Transfer article',
    transfer: 'Transfer',
    transferred_state: 'Transferred article',
    open_original: 'Article was transferred. Open original'
  },
  modal: {
    related_articles_header: 'Select related articles',
    related_articles_header_tag: 'Set tag',
    related_disease_articles_header: 'Select related disease articles',
    related_disease_articles_header_tag: 'Set tag',
    quiz_header: 'Select quiz',
    recipe_header: 'Select recipe',
    test_header: 'Select test',
    print_publication_header: 'Select print publication',
    print_publication_category: 'Print publication category',
    article_history_header: 'Article history',
    create_new_modal_header: 'Create new article',
    create_new_modal_create: 'Create',
    refresh_url_header: 'Do you want to regenerate article url ?',
    before_close_header_1: 'Warning ! Your changes are not saved.',
    before_close_header_2: 'Do you want to close opened draft ?',
    before_close_yes: 'Yes, close',
    before_close_no: 'No',
    set_hero_image_header: 'Naozaj chcete nastaviť Hlavný obrázok ?',
    yes: 'Yes',
    no: 'No',
    insert_all_ai_generated_related_articles: 'Mark all recommended',
    select: 'Select',
    insert: 'Insert',
    youtube_header: 'Insert youtube video',
    youtube_url: 'Youtube URL / ID',
    youtube_url_warning: 'Vybrané video je momentálne nedostupné, ste si istí?',
    youtube_url_unauthorized: 'The author of this video has prohibited embedding of the video on websites.',
    ooyala_header: 'insert ooyala video',
    ooyala_id: 'Ooyala ID',
    ooyala_id_status_warning: 'Video nie je v stave live, chcete ho aj tak pridať?',
    ooyala_id_preview_warning: 'Video nemá náhľadový obrázok !',
    ooyala_id_warning: 'Nesprávne ooyala video id !',
    infobox_header: 'Insert Infobox',
    infobox_create: 'Create new Infobox',
    infobox_select: 'Select existing infobox',
    infobox_create_header: 'Vložiť Infobox',
    infobox_title: 'Title',
    infobox_heading: 'Heading',
    infobox_bodytext: 'BodyText',
    poll_header: 'Insert poll',
    poll_update_votes: 'Update votes',
    poll_create: 'Create new poll',
    poll_select: 'Select existing poll',
    poll_type: 'Type',
    poll_question: 'Question',
    poll_display_type: 'Display type',
    poll_restriction_type: 'Restriction type',
    poll_end_of_voting: 'End of voting',
    poll_order_date: 'Order date',
    poll_published_until: 'Published until',
    poll_show_published_since: 'Postpone',
    poll_published_since: 'Published since',
    poll_hidden: 'Hidden',
    poll_enabled_voting: 'Enabled voting',
    poll_options: 'Options',
    poll_option: 'Option no.',
    poll_option_title: 'Title *',
    poll_add_option: 'Add option',
    quote_header: 'Insert quote',
    quote_title: 'Quote title',
    quote_author: 'Quote author',
    sport_table_header: 'Insert sport table',
    sport_table_seasonId: 'Table Id',
    sport_table_activeTab: 'Active tab (1 = first)',
    sport_online_header: 'Insert Online sport',
    sport_online_matchId: 'Match ID',
    sport_online_category: 'Sport category',
    gallery_link_header: 'Insert gallery link',
    gallery_link_pageNumber: 'Page number',
    gallery_link_linkText: 'Link text',
    html_header: 'Insert external object',
    html_code: 'HTML code',
    html_code_placeholder: 'Insert html code',
    unsaved_version: {
      header: 'Automatic article saving',
      body: 'The system automatically saved the article in progress. Do you want to continue from the last change?',
      footer: 'Last modified {lastModifiedText} by {username}',
      lastModifiedText: '{date} at {time}',
      today: 'today',
      yesterday: 'yesterday',
      offline_saved_version: 'You seem to be offline. The last saved version of {lastModifiedText}. Any other changes may not be saved.',
      offline_no_saved_version: 'You seem to be offline. No autosaved version found. Any other changes may not be saved.',
      save_log_error: 'An error occurred while saving automatically the article. The last saved version of {lastModifiedText}. Any other changes may not be saved.',
      loading_log_success: 'Automatically saved changes have been restored',
      loading_log_error: 'Automatically saved changes failed to restore',
      yes: 'Yes',
      no: 'No'
    },
    save_failed: {
      header: 'Concept not saved',
      body: 'Concept not saved. Please try again or restore the last version: {lastModifiedText}.',
      save: 'Save',
      restore: 'Restore last version'
    }
  },
  preview: {
    article_preview: 'Náhľad článku',
    next: 'Next',
    prev: 'Prev',
    page_no: 'Page no.',
    preview_error: 'Preview error',
    preview_expired: 'The preview has expired. To generate a new preview, you need to open Edit article and press this button:'
  },
  notify: {
    please_fill_all_required_fields: 'Please fill all required fields !',
    new_draft_created: 'New article draft was created !',
    new_draft_created_from_published: 'New draft from published was created.',
    draft_was_saved: 'Article draft was saved',
    draft_save_error_500: 'Article draft save error',
    draft_save_error_loader_stuck: 'Article draft save error. Saving took more than 60 seconds and was aborted.',
    draft_save_error: 'Article draft save error. Please check whether you have this article open to edit in another tab.',
    draft_load_error: 'Article load error. Close the article and try again.',
    article_status_changed_to_draft: 'Article status changed to draft.',
    article_status_changed_to_review: 'Article status changed to review.',
    article_status_changed_to_ready: 'Article status changed to ready.',
    article_status_changed_to_ready_forbidden: 'Nemáte oprávnenie na zmenu stavu do stavu ČAKÁ NA PUBLIKOVANIE',
    article_status_changed_to_draft_forbidden: 'Nemáte oprávnenie na zmenu stavu do stavu KONCEPT',
    article_in_draft_exists: 'Článok už má verziu v koncepte.',
    article_in_review_exists: 'Článok už má verziu na schválenie.',
    article_in_ready_exists: 'Článok už má naplánovanú verziu.',
    entity_has_redirect: 'The article cannot be unpublished because it has an active Redirect!',
    article_url_refresh_success: 'Article url was updated',
    article_url_refresh_error: 'Error, Article url was not updated.',
    infobox_created: 'Infobox was created !',
    poll_created: 'Poll was created !',
    youtube_thumbnail_error: 'Error ! Problem to retrieve Youtube thumbnail.',
    paidContentEmbedMissing: 'Článok je nastavený ako zamknutý/platený, ale chýba mu bod uzamknutia.',
    paidContentNotChecked: 'Článok má bod uzamknutia, ale nie je nastavený ako zamknutý/platený.',
    paidContentEmbedMultiple: 'Máte nastavených viac bodov uzamknutia, maximum je 1.',
    pageTitlesPageBreaksCount: 'Počet zalomení nesedí s počtom názvov stránok stránkovaného článku'
  },
  article_type: {
    article: 'Internal',
    external: 'External',
    video: 'Video',
    quiz: 'Quiz',
    photostory: 'Photostory',
    test: 'Test',
    longform: 'Longform',
    recipe: 'Recipe',
    disease: 'Disease',
    encyclopedy: 'Encyclopedy',
    onlineReportage: 'Reportage'
  },
  article_layout: {
    layout_0: 'default',
    layout_1: 'layout 1',
    layout_2: 'layout 2',
    layout_3: 'layout 3'
  },
  test_discriminator: {
    title: 'Type',
    car: 'Car',
    restaurant: 'Restaurant',
    hotel: 'Hotel'
  },
  notificationService: {
    notification: 'Notification',
    notificationPostponed: 'Publish delayed notification',
    oneSignalNotified: 'Publish notification',
    remainingFromLimit: '{remaining} remaining / {limit} limit'
  },
  hp_topic: {
    add: '+ Add next',
    remove: '- Remove',
    approved: 'Editor approved',
    date: 'Homepage publish date',
    offer: 'Homepage topic offer',
    slot: 'Time slot (hrs.)'
  }
}

export default messages
